<template>
  <div id="app">
    <div class="cart-con confirm-con">
      <div class="cart-ban">
        <img src="@/assets/img/confirm-ban.png" />
      </div>
      <div class="cson-container">
        <div class="current">
          <ul>
            <li>
              <img src="@/assets/img/confirm-cur1.png" />
              <p class="cur">确认客户订单</p>
              <span></span>
            </li>
            <li>
              <img src="@/assets/img/confirm-cur2.png" />
              <p>订单支付</p>
              <span></span>
            </li>
            <li>
              <img src="@/assets/img/confirm-cur3.png" />
              <p>支付结果</p>
            </li>
          </ul>
        </div>
        <template>
          <el-table
            ref="multipleTable"
            :data="tableData"
            :show-header="false"
            class="cart-table"
            style="width: 100%"
            tooltip-effect="dark"
          >
            <el-table-column label="课程" width="800">
              <template slot-scope="scope">
                <div class="lesson" style="padding-left: 280px">
                  <img :src="scope.row.imgUrl" class="img" style="left: 72px" />
                  <h2>{{ scope.row.objectName }}</h2>
                  <p>
                    <span
                      v-for="(item, index) in scope.row.tags"
                      :key="index"
                      >{{ item }}</span
                    >
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="金额" prop="money">
              <template slot-scope="scope">
                <div class="money">${{ scope.row.price || 0 }}</div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div
          v-if="!haveDiscount"
          class="coupons"
          style="text-align: right; margin-top: 20px"
        >
          <el-form label-width="100px" style="display: inline-block">
            <el-form-item label="优惠码">
              <el-input
                v-model="couponKey"
                style="width: 300px; margin-right: 12px"
              ></el-input>
              <el-button type="danger" @click="getDiscountPrice"
                >立即兑换</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- <div v-if="coupons && coupons.length > 0" class="has-coupon">
          <div class="account-coupon clearfix">
            <div class="sm">
              优惠券<span
                >已为你选择最高立减，共优惠${{
                  couponSelect.discount || 0
                }}</span
              >
            </div>
            <div
              v-for="(coupon, index) in coupons"
              :key="index"
              class="coupon-bg"
              @click="couponSelection(coupon)"
            >
              <h2><span>¥</span>{{ coupon.discount }}</h2>
              <p>
                有效期截止至：{{ coupon.expireTime }}{{ coupon.description }}
              </p>
            </div>
          </div>
        </div> -->
        <!--        <div class="sum">-->
        <!--          <h3>优惠码</h3>-->
        <!--          <input />-->
        <!--          <button>立即兑换</button>-->
        <!--        </div>-->
        <div class="forpay">
          <template>
            <div class="total">
              <label>{{ tableData.length }}</label
              >件商品，总商品金额：<span>${{ comboOriginalPrice }}</span>
            </div>
          </template>

          <div class="total" v-if="haveDiscount">
            优惠金额：<span>-${{ discountPrice }}</span>
          </div>
          <div class="total">
            应付金额：<span v-if="Number(totalPrice)" class="sure"
              >${{ totalPrice }}</span
            >
            <span v-else class="sure">¥0</span>
          </div>
          <div class="total">
            <button @click="gopay">提交订单</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 是否继续购买 -->
    <!-- <div class="submit-con" v-if="dialogFormVisible">
      <div class="box zhuan-box pay-box" style="height: 215px; width: 345px">
        <div class="c-title">
          <div>您购买的《北美SDE算法速成班》，部分课程已重复，是否继续购买？</div>
          <div>继续购买则该课程有效期将会延长【4个月】</div>
        </div>
        <div class="c-btn">
          <div @click="goIsNotPay()">确 定</div>
          <div @click="dialogFormVisible = false">取 消</div>
        </div>
      </div>
    </div> -->

    <!-- 移步到订单中心 -->
    <div v-if="dialogFormVisibleMoving" class="submit-con">
      <div class="box zhuan-box pay-box" style="height: 200px; width: 320px">
        <div class="c-title">此课程已存在未支付订单，请移步订单中心</div>
        <div class="c-btn">
          <div @click="showOrder()">移步订单中心</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  createOrder,
  getCouponsForOrder,
  isNotPay,
  getCouponDiscountPrice,
} from "@/service";
import { createMockOrder, mockIsNotPay } from "@/service/mock";
import {
  getStorage,
  getStorageForArray,
  navigateBack,
} from "../../utils/common";

export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      totalPrice: 0, //应付金额
      comboOriginalPrice: "", //总金额
      coupons: [],
      couponSelect: {},

      orderInfo: {},
      dialogFormVisible: false,
      dialogFormVisibleMoving: false,
      orderType: 1, //订单类型 1:单个 2：组合套餐 3:订阅
      couponKey: "",
      haveDiscount: false,
      discountPrice: 0,
    };
  },
  watch: {},
  created() {},
  mounted() {
    // 支付页面定位到顶部
    window.scrollTo(0, 0);
    this.init();
  },
  methods: {
    /**
     * @description 初始化页面数据
     */
    init() {
      const orderType = this.$route.query.type ? this.$route.query.type : 1;
      this.orderType = orderType;
      // console.log("orderType", orderType);

      if (Number(orderType) === 2) {
        let items = JSON.parse(sessionStorage.getItem("calcOrder"));

        const comboId = items.comboId,
          comboDiscountPrice = items.comboDiscountPrice, //应付金额
          comboOriginalPrice = items.comboOriginalPrice; //套餐总价

        const list = items.courseClassVOs.reduce((cur, next) => {
          cur.push({
            classId: next.courseClassId,
            comboId: comboId,
            id: "",
            imgUrl: next.cover,
            objectId: comboId,
            objectName: next.className,
            price: next.originalPrice,
            comboDiscountPrice: comboDiscountPrice,
          });
          return cur;
        }, []);

        this.totalPrice = comboDiscountPrice ? comboDiscountPrice : "0.00"; //应付金额
        this.comboOriginalPrice = comboOriginalPrice
          ? comboOriginalPrice
          : "0.00";

        this.tableData = list;
        console.log(this.tableData);
        //test
        // const order = getStorageForArray("orderSelect");
        // let temp = [];
        // order.forEach((item) => {
        //   temp.push(JSON.parse(item));
        // });
        // this.tableData = temp;
        // console.log("tableData", this.tableData);
      } else {
        this.totalPrice = getStorage("totalprice");
        this.comboOriginalPrice = getStorage("totalprice");
        const order = getStorageForArray("orderSelect");
        console.log("order", JSON.parse(order));
        if (order && order.length > 0) {
          let temp = [];
          order.forEach((item) => {
            temp.push(JSON.parse(item));
          });
          this.tableData = temp;
        } else {
          this.$message.error("数据错误，请重新下单");
          navigateBack(-1, 2000);
          return;
        }
        // this.getCoupons();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 提交订单
    gopay() {
      if (this.orderType != 3) {
        console.log(this.tableData);
        let params = { details: [], userCouponId: "" };
        this.tableData.forEach((item) => {
          params.details.push({
            classId: item.classId,
            comboId: item.objectId,
            activeId: item.activeId,
            groupBuyId: item.groupBuyId,
          });
        });
        params.userCouponId = this.couponSelect.userCouponId || "";
        console.log(params);

        this.orderInfo = params;
      }

      this.goIsNotPay();
      // 检测重复课程
      //   checkUserCourse(params)
      //     .then((res) => {
      //       if (!res.result.code) {
      //         console.log(res);
      //         if (res.result) {
      //           this.dialogFormVisible = true;
      //         } else {
      //           this.goIsNotPay();
      //         }
      //       } else {
      //         this.$message.error(res.result.message);
      //       }
      //     })
      //     .catch((err) => {
      //       console.log("检查是否已经拥有该课程失败");
      //     });
    },
    // 确定去支付订单
    goIsNotPay() {
      console.log(this.orderType);
      if (this.orderType != 3) {
        const params = this.orderInfo;

        console.log("orderInfo", this.orderInfo);
        isNotPay(params)
          .then((res) => {
            if (!res.result.code) {
              console.log(res);
              if (res.result) {
                this.dialogFormVisible = false;
                this.dialogFormVisibleMoving = true;
              } else {
                this.addOrder();
              }
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            console.log("当前班课是否有未支付的订单失败");
          });
      } else {
        if (this.tableData && this.tableData.length > 0) {
          mockIsNotPay(this.tableData[0].objectId)
            .then((res) => {
              if (!res.result.code) {
                console.log(res);
                if (res.result) {
                  this.dialogFormVisible = false;
                  this.dialogFormVisibleMoving = true;
                } else {
                  this.addOrder();
                }
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              console.log("当前班课是否有未支付的订单失败");
            });
        }
      }
    },
    // 创建订单
    addOrder() {
      if (this.orderType != 3) {
        const params = this.orderInfo;
        createOrder({
          ...params,
          code: this.couponKey,
          createTime: this.dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
        })
          .then((res) => {
            console.log("订单创建：", res);

            if (res.success) {
              // const order_key = `order_create_${res.result.orderId}`;
              // setStorage(order_key, res.result, true);
              // setStorage("cur_od_i", res.result.orderId);
              // 跳到选择支付方式组建中
              this.$router.push({
                path: "/orderpay",
                query: { orderId: res.result.orderId },
              });
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            console.log("订单创建失败");
          });
      } else {
        createMockOrder(this.tableData[0].objectId, {
          code: this.couponKey,
          createTime: this.dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
        })
          .then((res) => {
            console.log("订单创建：", res);

            if (res.success) {
              // 跳到选择支付方式组建中
              this.$router.push({
                path: "/orderpay",
                query: { orderId: res.result.orderId },
              });
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            console.log("订单创建失败");
          });
      }
    },
    // 移步订单中心
    showOrder() {
      this.dialogFormVisible = false;
      this.dialogFormVisibleMoving = false;
      this.$router.push("center/order");
    },
    // getCoupons() {
    //   let classIds = [];
    //   this.tableData.forEach((item) => {
    //     classIds.push(item.objectId);
    //   });
    //   getCouponsForOrder(classIds.join(","))
    //     .then((res) => {
    //       console.log("coupons:", res);
    //       if (res.success) {
    //         this.coupons = res.result;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("coupons fail:", err);
    //     });
    // },
    couponSelection(coupon) {
      console.log("已选择：", coupon);
      this.couponSelect = coupon;
    },
    dateFormat(thisDate, fmt) {
      var o = {
        "M+": thisDate.getMonth() + 1,
        "d+": thisDate.getDate(),
        "h+": thisDate.getHours(),
        "m+": thisDate.getMinutes(),
        "s+": thisDate.getSeconds(),
        "q+": Math.floor((thisDate.getMonth() + 3) / 3),
        S: thisDate.getMilliseconds(),
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (thisDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    getDiscountPrice() {
      let data = {};
      if (this.orderType == 1 || this.orderType == 2) {
        const courseClassIds = this.tableData.map((item) => {
          return item.classId;
        });
        data = {
          code: this.couponKey,
          courseClassIds: courseClassIds,
        };
      } else if (this.orderType == 3) {
        data = {
          code: this.couponKey,
          courseMockId: this.tableData[0].objectId,
        };
      }
      console.log(this.tableData, data);
      getCouponDiscountPrice(data).then((res) => {
        if (res.success) {
          this.haveDiscount = true;
          this.discountPrice = res.result;
          this.totalPrice = this.totalPrice - this.discountPrice;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.c-title {
  text-align: center;
  padding: 20px;
}

.c-title div:nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.c-title div:nth-child(2) {
  margin-top: 12px;
  font-weight: 400;
  color: #fa6400;
  cursor: pointer;
}

.c-btn {
  display: flex;
  margin-bottom: 20px;
  margin-left: 16px;
  margin-top: 30px;
}

.c-btn div {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 22px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  border-radius: 2px;
}

.c-btn div:nth-child(1) {
  color: white;
  background: linear-gradient(to left, #fd1d1d, #fcb045);
  margin-right: 20px;
  cursor: pointer;
}

.c-btn div:nth-child(2) {
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
</style>
